const initState = () => ({
  users: [],
  customers: [],
  dealersForCountry: [],
})

export const state = initState

export const mutations = {
  setUsers(state, {users}) {
    state.users = users
  },
  setCustomers(state, {customers}) {
    state.customers = customers
  },
  setDealersForCountry(state, dealersForCountry) {
    state.dealersForCountry = dealersForCountry
  },
}

export const actions = {
  async fetchUsers({commit}, {customerId}) {
    const users = await this.$axios.$get('/User/getPortalUsersByCustomerId/' + customerId)
    commit('setUsers', {users})
  },
  async fetchCustomersWithActivePortalUsers({commit}) {
    const customers = await this.$axios.$get('/Customers/getCustomersWithActivePortalUser')
    commit('setCustomers', {customers})
  },
  async fetchCustomers({commit}) {
    const customers = await this.$axios.$get('/Customers')
    commit('setCustomers', {customers})
  },
  async fetchDealers({commit}) {
    const dealersForCountry = await this.$axios.$get('/Customers/getDealersForCountry')
    commit('setDealersForCountry', dealersForCountry.value)
  },
}
