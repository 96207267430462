const initState = () => ({
  closedQuoteStatuses: [],
  openQuoteStatuses: [],
  invoiceStatuses: [],
  salesOrderStatuses: [],
  jobOrderStatuses: [],
})

export const state = initState

export const mutations = {
  setClosedQuoteStatuses(state, {closedQuoteStatuses}) {
    state.closedQuoteStatuses = closedQuoteStatuses
  },
  setOpenQuoteStatuses(state, {openQuoteStatuses}) {
    state.openQuoteStatuses = openQuoteStatuses
  },
  setInvoiceStatuses(state, {invoiceStatuses}) {
    state.invoiceStatuses = invoiceStatuses
  },
  setSalesOrderStatuses(state, {salesOrderStatuses}) {
    state.salesOrderStatuses = salesOrderStatuses
  },
  setJobOrderStatuses(state, {jobOrderStatuses}) {
    state.jobOrderStatuses = jobOrderStatuses
  },
}

export const actions = {
  fetchClosedQuoteStatuses({commit}) {
    return this.$axios
      .$get('/EntityStatus/ClosedPriceQuoteStatuses')
      .then((statuses) => {
        commit('setClosedQuoteStatuses', {
          closedQuoteStatuses: statuses.map((element) => {
            return {text: element.name, value: element.id}
          }),
        })
      })
  },

  fetchOpenQuoteStatuses({commit}) {
    return this.$axios
      .$get('/EntityStatus/OpenPriceQuoteStatuses')
      .then((statuses) => {
        commit('setOpenQuoteStatuses', {
          openQuoteStatuses: statuses.map((element) => {
            return {text: element.name, value: element.id}
          }),
        })
      })
  },
  async fetchInvoiceStatuses({commit}) {
    const invoiceStatuses = await this.$axios.$get('/EntityStatus/invoiceStatuses')
    commit('setInvoiceStatuses', {invoiceStatuses})
  },

  fetchSalesOrderStatuses({commit}) {
    return this.$axios.$get('/EntityStatus/SalesOrder').then((statuses) => {
      commit('setSalesOrderStatuses', {
        salesOrderStatuses: statuses.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  fetchJobOrderStatuses({commit}) {
    return this.$axios.$get('/EntityStatus/JobOrder').then((statuses) => {
      commit('setJobOrderStatuses', {
        jobOrderStatuses: statuses.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
}
