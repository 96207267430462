import {formActions} from '@/enums/formActions'

const initState = () => ({
  modules: [],
})

export const state = initState

export const getters = {
  getModules: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    return state.modules.filter(
      (x) => regexp.test(x.name) || regexp.test(x.code)
    )
  },
}

export const mutations = {
  setModules(state, {modules}) {
    state.modules = modules
  },
}

export const actions = {
  fetchModules({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const modules = await this.$axios.$get('/modules/cp')
          // commit('setModules', {modules})
          resolve({modules})
        },
        formActions.Load,
        'modules'
      )
    })
  },

  async updateRoles({commit}, {roles, value}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/modules/cp/roles`, roles.roleSettings)
        this.$toast.success('Successfully saved role settings.')
      },
      formActions.Save,
      'Role settings'
    )
  },
  saveModule({commit}, {module, value}) {
    this.$catch(
      async () => {
        await this.$axios.$post(`/modules/cp`, module)
        commit('setModule', {module})
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', module.name)
        )
      },
      formActions.Save,
      module.name
    )
  },
  async postFieldSettings({commit}, {fieldSettings}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/modules/cp/fieldsSettings`, fieldSettings)
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', 'Field settings')
        )
      },
      formActions.Save,
      'Field settings'
    )
  },
}
