const initState = () => ({
  creditCards: [],
  creditCardTypes: [],
})

export const state = initState

export const getters = {
  getCreditCardTypes: (state) => () => {
    return state.creditCardTypes
  },
}

export const mutations = {
  addCreditCard(state, {creditCard}) {
    state.creditCards.push(creditCard)
  },
  setCreditCards(state, {creditCards}) {
    state.creditCards = creditCards
  },
  setCreditCardTypes(state, {creditCardTypes}) {
    state.creditCardTypes = creditCardTypes
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchCreditCards({commit}) {
    if (
      this.$auth.loggedIn &&
      this.$auth.user.permissions.find((x) => x === 'CP_PAYMENTS:VIEWP')
    ) {
      return this.$axios.$get('/CreditCard').then((creditCards) => {
        commit('setCreditCards', {creditCards})
      })
    }
  },
  fetchCreditCardTypes({commit}) {
    if (
      this.$auth.loggedIn &&
      this.$auth.user.permissions.find((x) => x === 'CP_PAYMENTS:VIEWP')
    ) {
      return this.$axios.$get('/CreditCard/types').then((creditCardTypes) => {
        commit('setCreditCardTypes', {
          creditCardTypes: creditCardTypes.map((element) => {
            return {text: element.name, value: element.id}
          }),
        })
      })
    }
  },
}
